<template>
  <div :style="zoneStyle">
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <div class="header" :style="headerHeight">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" sm="2" md="2">
          <h2 class="ml-4 mt-2">{{ $t("zones.add_dialog_title") }}</h2>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-select
            v-model="selectedAspect"
            :items="aspect"
            item-text="text"
            rounded
            filled
            :loading="loading"
            class="pt-1"
            @change="formatZones"
            style="max-width: 80%"
            item-value="value"
            :label="$t('zones.aspect_ratio')"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-select
            v-model="selectedGrid"
            :items="grid"
            item-text="text"
            class="pt-1"
            rounded
            filled
            style="max-width: 80%"
            :loading="loading"
            item-value="value"
            :label="$t('zones.grid')"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-text-field
            type="color"
            style="max-width: 60%"
            v-model="selectedColor"
            required
            :label="$t('zones.background_color')"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn class="close" icon dark @click="$emit('cancel')">
        <v-icon>fa-times</v-icon>
      </v-btn>
    </div>

    <div id="secondary-zone" :style="bodyHeight">
      <div
        v-if="resizing"
        :style="`justify-content: center; width: 100%; display: flex;`"
      >
        <div class="resizing-zones" :style="workArea">
          <h3>{{$t('zones.updating_layout')}}</h3>
        </div>
      </div>

      <div v-else style="justify-content: center; width: 100%; display: flex">
        <div id="workArea" class="main-zone" :style="workArea">
          <vue-draggable-resizable
            :w="item.width"
            :h="item.height"
            :x="item.x"
            :y="item.y"
            v-for="(item, index) of zones"
            :key="index"
            class="item-added"
            :style="`background-color: ${item.color ? item.color : '#000000'}`"
            class-name-dragging="dragging"
            class-name-active="item-active"
            class-name-resizing="resizing"
            @dragging="onDrag"
            @activated="activateZone(index)"
            @resizing="onResize"
            :parent="true"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  v-on:dblclick="(counter += 1), editComponent(item, index)"
                  class="cont-item"
                  v-on="on"
                >
                  <i
                    @click="editComponent(item, index)"
                    v-if="!item.type"
                    class="fas fa-plus"
                  ></i>

                  <player
                    v-if="item.type == 'player'"
                    :width="item.width"
                    :height="item.height"
                    :key="item.playlist"
                    :adjustment="item.adjustment"
                    :playlistId="item.playlist"
                  />
                  <marquee
                    :dimensions="dimensions"
                    v-if="item.type == 'marquee'"
                    :item="item"
                  />
                  <notices
                    :dimensions="dimensions"
                    v-if="item.type == 'notices'"
                    :item="item"
                  />
                  <single-file
                    v-if="item.type == 'singleFile'"
                    :width="item.width"
                    :height="item.height"
                    :adjustment="item.adjustment"
                    :key="item.fileId"
                    :item="item"
                  />

                  <first-time-template
                    v-if="item.type == 'time'"
                    :dimensions="dimensions"
                    :width="item.width"
                    :height="item.height"
                    :configuration="item.configuration"
                  />

                  <iframe
                    v-if="item.type == 'iframe'"
                    :src="item.url"
                    class="pa-5"
                    frameborder="0"
                    id="iframe"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    :width="item.width"
                    :height="item.height"
                  ></iframe>

                  <streaming
                    v-if="item.type == 'streaming'"
                    :src="item.url"
                    :width="item.width"
                    :height="item.height"
                  />
                </div>
              </template>
              <span>{{ $t("zones.double_click") }}</span>
            </v-tooltip>
          </vue-draggable-resizable>
        </div>
      </div>

      <div class="actions">
        <v-text-field
          v-model="name"
          :label="$t('dialog.name')"
          clearable
          :loading="loading"
          rounded
          filled
        ></v-text-field>
        <v-list style="max-height: 60vh; overflow-y: auto">
          <v-subheader>{{ $t("zones.components") }}</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              class="mt-3"
              :disabled="!item.active"
              v-for="(item, i) in originalItem ? availableItems : items"
              @click="addComponent(item)"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon
                  style="font-size: 20px !important"
                  class="pr-5"
                  v-text="item.icon"
                >
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-col cols="12" sm="12" md="12">
          <v-btn
            :loading="loading"
            block
            color="buttons"
            :elevation="0"
            @click="originalItem ? update() : save()"
            class="flex-grow-1 flex-shrink-0 white--text mt-5"
            :minHeight="56"
          >
            {{ $t("button.save") }}
          </v-btn>
        </v-col>
      </div>
    </div>

    <v-dialog
      v-if="componentDialog"
      persistent
      :max-width="selectedComponent == 'time' ? '80%' : '700px'"
      v-model="componentDialog"
    >
      <new-component
        :dimensions="numericSize"
        @updated="handleUpdate"
        :selectedItem="selectedItem"
        @success="added"
        :type="selectedComponent"
        @cancel="componentDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="selectFileDialog"
      max-width="35%"
      v-model="selectFileDialog"
    >
      <select-file
        :fileType="fileType"
        :selectedItem="selectedItem"
        @updated="handleUpdate"
        @success="added"
        @cancel="selectFileDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="templatesDialog"
      max-width="1200px"
      v-model="templatesDialog"
    >
      <default-templates
        @selected="templateSelected"
        :dimensions="numericSize"
        @cancel="templatesDialog = false"
      />
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-if="widgetsDialog"
      v-model="widgetsDialog"
    >
      <template>
        <v-card>
          <v-card-title class="grid-close">
            <span class="headline">{{ $t("zones.select_content") }}</span>
            <v-btn
              style="right: 10px; top: 10px;  border-radius : 30px!important"
              icon
              color="primary"
              fab
              absolute
              @click="widgetsDialog = false"
            >
              <v-icon dark>fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-list style="background-color: white !important">
                    <v-list-item-group color="white">
                      <v-list-item
                        class="mt-3"
                        :disabled="!item.active"
                        v-for="(item, i) in availableItems"
                        @click="
                          selectedComponent = item.type;
                          updateZoneType();
                        "
                        :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon
                            style="font-size: 20px !important"
                            class="pr-5"
                            v-text="item.icon"
                          >
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <vue-draggable-resizable
      :resizable="false"
      :w="280"
      :h="520"
      class="details"
      v-if="showDetails"
      :parent="true"
    >
      <i @click="showDetails = false" class="fas fa-times close-details"></i>
      <v-text-field
        :label="$t('zones.dimensions.width')"
        v-model.number="zones[selectedZone].width"
        class="mt-8"
        rounded
        type="number"
        filled
        persistent-hint
        :hint="`${(
          (zones[selectedZone].width / dimensions.width) *
          100
        ).toFixed(1)}% ${$t('zones.dimensions.of_width')}.`"
      ></v-text-field>

      <v-text-field
        :label="$t('zones.dimensions.height')"
        rounded
        v-model.number="zones[selectedZone].height"
        :hint="`${(
          (zones[selectedZone].height / dimensions.height) *
          100
        ).toFixed(1)}% ${$t('zones.dimensions.of_height')}.`"
        persistent-hint
        type="number"
        filled
      ></v-text-field>

      <v-text-field
        :value="`${((zones[selectedZone].x / dimensions.width) * 100).toFixed(
          1
        )}%`"
        :label="$t('zones.dimensions.initial_position_in_x')"
        rounded
        disabled
        filled
      ></v-text-field>

      <v-text-field
        :value="`${((zones[selectedZone].y / dimensions.height) * 100).toFixed(
          1
        )}%`"
        :label="$t('zones.dimensions.initial_position_in_y')"
        rounded
        disabled
        filled
      ></v-text-field>

      <v-btn
        class="flex-grow-1 flex-shrink-0 white--text"
        large
        rounded
        :elevation="0"
        :minHeight="36"
        @click="editComponent(zones[selectedZone], selectedZone)"
        block
        color="primary"
      >
        {{ $t("button.edit") }}
      </v-btn>

      <v-btn
        class="flex-grow-1 flex-shrink-0 white--text mt-3"
        large
        rounded
        :elevation="0"
        @click="remove(selectedZone)"
        :minHeight="36"
        block
        color="red darken-4"
      >
        {{ $t("delete.delete_button") }}
      </v-btn>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import newComponent from "./new-component";
import player from "./components/player";
import Player from "./components/player.vue";
import marquee from "./components/marquee";
import notices from "./components/notices";
import firstTimeTemplate from "./components/time/template1";
import selectFile from "./components/single-image/source.vue";
import singleFile from "./components/single-image/view-file";
import defaultTemplates from "./components/default-templates";
import streaming from "./components/streaming";

export default {
  name: "new-zone",
  props: ["originalItem"],
  components: {
    VueDraggableResizable,
    newComponent,
    player,
    marquee,
    notices,
    selectFile,
    firstTimeTemplate,
    singleFile,
    defaultTemplates,
    streaming,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      showDetails: false,
      resizing: false,
      counter: 0,
      name: "",
      dimensions: null,
      selectedItem: null,
      selectedAspect: 2,
      selectedZone: null,
      selectFileDialog: false,
      templatesDialog: false,
      widgetsDialog: false,
      selectedComponent: null,
      selectedColor: "#d4d4d4",
      componentDialog: false,
      selectedGrid: 2,
      fileType: "",
      zones: [],
      items: [
        {
          text: this.$t("zones.default_templates"),
          icon: "fas fa-th-large",
          type: "defaultTemplate",
          active: true,
        },
        {
          text: this.$tc("zones.notices", 2),
          icon: "fas fa-newspaper",
          type: "notices",
          active: true,
        },
        {
          text: this.$t("zones.marquee"),
          icon: "fas fa-tags",
          type: "marquee",
          active: true,
        },
        {
          text: this.$t("zones.media_player"),
          icon: "fas fa-play-circle",
          type: "player",
          active: true,
        },
        {
          text: this.$t("zones.single_image"),
          icon: "fas fa-image",
          type: "singleImage",
          active: true,
        },
        {
          text: this.$t("zones.single_video"),
          icon: "fas fa-video",
          type: "singleVideo",
          active: true,
        },
        {
          text: "Streaming",
          icon: "fas fa-satellite",
          type: "streaming",
          active: true,
        },
        {
          text: "Iframe",
          icon: "fas fa-window-maximize",
          type: "iframe",
          active: true,
        },
      ],
      aspect: [
        { text: "4:3", value: 0 },
        { text: "5:4", value: 1 },
        { text: "16:9", value: 2 },
        { text: "16:10", value: 3 },
      ],
      grid: [
        { text: this.$t("zones.no_grid"), value: 1 },
        { text: "2x2", value: 2 },
        { text: "3x3", value: 3 },
        { text: "4x4", value: 4 },
        { text: "5x5", value: 5 },
        { text: "6x6", value: 6 },
        { text: "7x7", value: 7 },
        { text: "8x8", value: 8 },
        { text: "9x9", value: 9 },
        { text: "10x10", value: 10 },
      ],
    };
  },

  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
    zoneStyle() {
      return `width : ${window.innerWidth}px;
        background-color:white;
        padding-right:10px;
        overflow-y:hidden`;
    },
    headerHeight() {
      return `height : ${window.innerHeight * 0.1}px`;
    },
    bodyHeight() {
      return {
        height: `${window.innerHeight * 0.9}px`,
        display: "flex",
        "justify-content": "flex-start",
      };
    },
    workArea() {
      let reductionFactor = 350;

      let availableWidth = window.innerWidth - reductionFactor;

      let availableHeight = window.innerHeight * 0.85;

      let height = null;

      switch (this.selectedAspect) {
        case 0:
          //4/3 1.33333333333
          height = (availableWidth * 3) / 4;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 3) / 4;
          }
          break;
        case 1:
          // 5/4 1.25
          height = (availableWidth * 4) / 5;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 4) / 5;
          }
          break;
        case 2:
          // 16/9  1.777777777777
          height = (availableWidth * 9) / 16;

          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;

            availableWidth = window.innerWidth - reductionFactor;

            height = (availableWidth * 9) / 16;
          }

          break;
        case 3:
          //16/10 1.6
          height = (availableWidth * 10) / 16;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 10) / 16;
          }

          break;
      }

      return {
        "background-color": this.selectedColor ? this.selectedColor : "#d4d4d4",
        width: `${availableWidth}px`,
        height: `${height}px`,
        "background-size": `${availableWidth / this.selectedGrid}px ${
          height / this.selectedGrid
        }px, ${availableWidth / this.selectedGrid}px ${
          height / this.selectedGrid
        }px`,
      };
    },
    numericSize() {
      let reductionFactor = 350;
      let availableWidth = window.innerWidth - reductionFactor;

      let availableHeight = window.innerHeight * 0.85;

      let height = null;

      switch (this.selectedAspect) {
        case 0:
          //4/3 1.33333333333
          height = (availableWidth * 3) / 4;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 3) / 4;
          }
          break;
        case 1:
          // 5/4 1.25
          height = (availableWidth * 4) / 5;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 4) / 5;
          }
          break;
        case 2:
          // 16/9  1.777777777777
          height = (availableWidth * 9) / 16;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 9) / 16;
          }
          break;
        case 3:
          //16/10 1.6
          height = (availableWidth * 10) / 16;
          while (height > availableHeight - 15) {
            reductionFactor = reductionFactor + 10;
            availableWidth = window.innerWidth - reductionFactor;
            height = (availableWidth * 10) / 16;
          }
          break;
      }
      return { width: availableWidth, height };
    },
    availableItems() {
      return this.items.slice(1, this.items.length);
    },
  },

  methods: {
    remove(index) {
      this.showDetails = false;
      this.zones.splice(index, 1);
    },
    activateZone(index) {
      this.selectedZone = index;
      this.showDetails = true;
    },
    editComponent(item, index) {
      this.counter = 0;

      this.selectedComponent = item.type;

      this.selectedItem = {
        data: item,
        index,
      };

      if (!item.type) {
        this.widgetsDialog = true;
      } else if (item.type != "singleFile") {
        this.componentDialog = true;
      } else {
        this.fileType = item.fileType;
        this.selectFileDialog = true;
      }
    },

    formatZones() {
      this.dimensions = this.numericSize;
      this.resizing = true;

      this.zones.forEach((item) => {
        if (item.widthPercentage) {
          item.width = Number.parseFloat(
            (item.widthPercentage / 100) * this.dimensions.width
          );
        }

        if (item.heightPercentage) {
          item.height = Number.parseFloat(
            (item.heightPercentage / 100) * this.dimensions.height
          );
        }

        if (item.xPercentage) {
          item.x = Number.parseFloat(
            (item.xPercentage / 100) * this.dimensions.width
          );
        }

        if (item.yPercentage) {
          item.y = Number.parseFloat(
            (item.yPercentage / 100) * this.dimensions.height
          );
        }
      });

      setTimeout(() => {
        this.resizing = false;
      }, 1000);
    },

    handleUpdate(data) {
      this.zones[this.selectedItem.index] = data;

      this.zones[this.selectedItem.index].type =
        this.selectedComponent == "singleVideo" ||
        this.selectedComponent == "singleImage"
          ? "singleFile"
          : this.selectedComponent;

      this.componentDialog = false;
      this.selectFileDialog = false;
    },

    templateSelected(item) {
      item.color = "#e6e6e0";
      this.zones = Object.assign([], item);
      this.selectedGrid = 1;
      this.selectedColor = "#d4d4d4";
      this.templatesDialog = false;
    },

    added(item) {
      const { width, height } = this.numericSize;
      this.componentDialog = false;
      this.selectFileDialog = false;
      item.widthPercentage = Number.parseFloat((item.width / width) * 100);
      item.heightPercentage = Number.parseFloat((item.height / height) * 100);
      item.xPercentage = Number.parseFloat((item.x / width) * 100);
      item.yPercentage = Number.parseFloat((item.y / height) * 100);
      this.zones.push(item);
    },

    updateZoneType() {
      if (this.selectedComponent) {
        this.widgetsDialog = false;

        if (
          this.selectedComponent == "singleImage" ||
          this.selectedComponent == "singleVideo"
        ) {
          this.fileType =
            this.selectedComponent == "singleImage" ? "image" : "video";
          this.selectFileDialog = true;
        } else {
          this.componentDialog = true;
        }
      }
    },

    addComponent(item) {
      this.selectedItem = null;
      this.selectedComponent = item.type;

      switch (item.type) {
        case "notices":
          this.componentDialog = true;
          break;

        case "marquee":
          this.componentDialog = true;
          break;

        case "player":
          this.componentDialog = true;
          break;

        case "singleImage":
          this.fileType = "image";
          this.selectFileDialog = true;
          break;

        case "singleVideo":
          this.fileType = "video";
          this.selectFileDialog = true;
          break;

        case "defaultTemplate":
          this.templatesDialog = true;
          break;

        default:
          break;

        case "streaming":
          this.componentDialog = true;
          break;

        case "iframe":
          this.componentDialog = true;
          break;
      }
    },
    onResize: function (x, y, newWidth, newHeight) {
      const { width, height } = this.numericSize;

      this.zones[this.selectedZone].x = x;
      this.zones[this.selectedZone].y = y;
      this.zones[this.selectedZone].width = newWidth;
      this.zones[this.selectedZone].height = newHeight;

      this.zones[this.selectedZone].widthPercentage = Number.parseFloat(
        (newWidth / width) * 100
      );
      this.zones[this.selectedZone].heightPercentage = Number.parseFloat(
        (newHeight / height) * 100
      );
      this.zones[this.selectedZone].xPercentage = Number.parseFloat(
        (x / width) * 100
      );
      this.zones[this.selectedZone].yPercentage = Number.parseFloat(
        (y / height) * 100
      );

      // this.resizing = true;
    },
    onDrag: function (x, y) {
      const { width, height } = this.numericSize;

      this.zones[this.selectedZone].x = x;
      this.zones[this.selectedZone].y = y;

      this.zones[this.selectedZone].xPercentage = Number.parseFloat(
        (x / width) * 100
      );
      this.zones[this.selectedZone].yPercentage = Number.parseFloat(
        (y / height) * 100
      );
    },
    update() {
      this.showDetails = false;
      let { width, height } = this.numericSize;
      let items = JSON.parse(JSON.stringify(this.zones));

      items = items.map((item) => {
        let zone = item;
        zone.width = Number.parseFloat(((zone.width / width) * 100).toFixed(1));
        zone.height = Number.parseFloat(
          ((zone.height / height) * 100).toFixed(1)
        );
        zone.x = Number.parseFloat(((zone.x / width) * 100).toFixed(1));
        zone.y = Number.parseFloat(((zone.y / height) * 100).toFixed(1));
        return zone;
      });

      if (this.name && items.length > 0) {
        this.loading = true;
        db.collection("zones")
          .doc(this.originalItem[".key"])
          .update({
            name: this.name,
            aspect: this.selectedAspect,
            grid: this.selectedGrid,
            components: items,
            modifiedBy: this.user.uid,
            modifiedAt: new Date(),
            backgroundColor: this.selectedColor
              ? this.selectedColor
              : "#d4d4d4",
          })
          .then((response) => {
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("zones.insert_name");
        this.snackbar = true;
      }
    },
    save() {
      this.showDetails = false;

      let { width, height } = this.numericSize;
      let items = JSON.parse(JSON.stringify(this.zones));

      items = items.map((item) => {
        let zone = item;
        zone.width = Number.parseFloat((zone.width / width) * 100);
        zone.height = Number.parseFloat((zone.height / height) * 100);
        zone.x = Number.parseFloat((zone.x / width) * 100);
        zone.y = Number.parseFloat((zone.y / height) * 100);
        return zone;
      });

      if (this.name && items.length > 0) {
        this.loading = true;
        db.collection("zones")
          .add({
            name: this.name,
            aspect: this.selectedAspect,
            grid: this.selectedGrid,
            components: items,
            createdBy: this.user.uid,
            createdAt: new Date(),
            groupId: this.groupId,
            backgroundColor: this.selectedColor
              ? this.selectedColor
              : "#d4d4d4",
          })
          .then((response) => {
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("zones.enter_name");
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.originalItem) {
      this.name = this.originalItem.name;
      this.selectedAspect = this.originalItem.aspect;
      this.selectedGrid = this.originalItem.grid ? this.originalItem.grid : 2;
      this.selectedColor = this.originalItem.backgroundColor
        ? this.originalItem.backgroundColor
        : "#d4d4d4";

      this.zones = JSON.parse(JSON.stringify(this.originalItem.components));

      this.dimensions = this.numericSize;

      this.zones.forEach((item) => {
        item.widthPercentage = item.width;
        item.heightPercentage = item.height;
        item.xPercentage = item.x;
        item.yPercentage = item.y;

        item.width = Number.parseFloat(
          (item.width / 100) * this.dimensions.width
        );
        item.height = Number.parseFloat(
          (item.height / 100) * this.dimensions.height
        );
        item.x = Number.parseFloat((item.x / 100) * this.dimensions.width);
        item.y = Number.parseFloat((item.y / 100) * this.dimensions.height);
      });
    } else {
      this.dimensions = this.numericSize;
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.main-zone {
  background-color: rgb(212, 212, 212);
  border: 1px solid rgba(0, 0, 0, 0.4);
  overflow: hidden !important;
  background-image: linear-gradient(
      -90deg,
      rgba(36, 36, 36, 0.4) 1px,
      transparent 0
    ),
    linear-gradient(rgba(48, 48, 48, 0.4) 1px, transparent 0);
  background-position: 0 0;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  margin-top: 20px;
}

.item-added {
  background-color: white;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  position: absolute;
  z-index: 9999;
  justify-items: center;
  min-height: 20px;
  min-width: 20px;
  cursor: pointer;
}

.dragging {
  opacity: 0.3;
  z-index: 9999 !important;
}

.resizing {
  opacity: 0.4;
  border: 1px solid red !important;
}

.item-active {
  z-index: 9999 !important;
}

.remove {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px !important;
  cursor: pointer;
  font-weight: bold;
}

.cont-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-item i {
  color: rgba(97, 97, 97, 0.5);
  font-size: 30px !important;
}

.details {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 50px;
  right: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 15px 0px #000000;
  box-shadow: 5px 5px 15px 0px #000000;
  z-index: 999;
  cursor: move;
}

.close-details {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: gray;
  font-size: 20px;
}

.zones-editor {
  width: 100vw;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close i {
  color: dimgrey !important;
  font-size: 20px !important;
  padding: 5px;
}

.actions {
  padding: 20px;
  min-width: 300px;
  max-width: 300px;
}

.loading-skeleton {
  background-color: white;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.resizing-zones {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resizing-zones h3 {
  color: rgb(255, 255, 255);
  font-weight: bold;
  background-color: rgba(128, 128, 128, 0.514);
  padding: 20px;
}
</style>