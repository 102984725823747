<template>
  <div :style="contStyles" class="player-main">
    <img v-if="item.fileType == 'image'" :style="styles" :src="item.url" />
    <video
      v-else
      loop
      :style="styles"
      autoplay
      muted
      :src="item.url"
      ref="videoPlayer"
    ></video>
  </div>
</template>

<script>
export default {
  name: "view-single-file",
  props: ["item", "adjustment", "width", "height"],
  data() {
    return {

    }
  },
  computed: {
    styles() {
      return `
        width : ${this.width}px ;
        max-width : ${this.width}px;
        height : ${this.height}px;
        max-height : ${this.height}px ; 
        object-fit : ${this.adjustment} ;
    `;
    },
    contStyles() {
      return `
        max-width : ${this.width}px;
        max-height : ${this.height}px ; 
    `;
    },
  }
}
</script>

<style scoped>
.player-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>