<template>
    <div :style="contStyles" class="player-main">
        <img  :style="styles" v-show="showImage" :src="imgSrc">
        <video :style="styles" ref="videoPlayer" v-show="showVideo"></video>
    </div>
</template>

<script>
import { db, fb } from "@/firebase";

    export default {
        name : 'player',
        props : ['playlistId', 'width', 'height', 'adjustment'],
        data() {
            return {
                files : [],
                playlist : null,
                imgSrc : '',
                videoSrc : '',
                loading: true,
                showVideo: false,
                showImage : false,
                index : 0
            }
        },
        computed: {
            styles(){
                return`
                    width : ${this.width}px ;
                    max-width : ${this.width}px;
                    height : ${this.height}px;
                    max-height : ${this.height}px ; 
                    object-fit : ${this.adjustment} ;
                `
            },
            contStyles(){
                return`
                    max-width : ${this.width}px;
                    max-height : ${this.height}px ; 
                `
            }
        },
        methods: {
           async start() {
                this.loading = false
                while (true) {
                    await this.playFile( this.files[this.index] )
                    this.index = (this.index == (this.files.length - 1)) ? 0 : this.index + 1
                }
            },
            playFile( item ) {
               return new Promise((resolve,reject) => {
                    if ( item.type == 'image' ) {
                        this.imgSrc = item.url
                        this.showImage = true
                        this.showVideo = false
                        setTimeout(() => {
                            resolve('success')
                        }, this.playlist.imageDuration * 1000 || 10000);
                    } else {
                        this.showVideo = true

                        if ( this.$refs.videoPlayer )  {
                            this.$refs.videoPlayer.src = item.url
                            this.$refs.videoPlayer.muted = true
                            this.showImage = false
                            this.$refs.videoPlayer.play()
                            this.$refs.videoPlayer.onended = () => {
                                resolve('success')
                            }
                        }
                    }
               })
            }
        },
        
        async mounted() {
            await this.$binding('playlist', db.collection('playlists').doc(this.playlistId))
            await this.$binding('files', db.collection('files').where(
                fb.firestore.FieldPath.documentId(),
                "in",
                this.playlist.files.slice(0,10)
            ))

            this.start()
        }
    }
</script>

<style scoped>
    .player-main {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>