<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? $t("dialog.edit") : $t("dialog.add") }}
          {{ name }}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form @submit.prevent="selectedItem ? update() : add()" ref="form">
            <!-- player fields -->
            <v-row v-if="type == 'player'">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  :items="playlists"
                  item-text="name"
                  rounded
                  filled
                  item-value="id"
                  :loading="loading"
                  required
                  :rules="[rules.required]"
                  v-model="item.playlist"
                  :label="$t('zones.playlists')"
                  persistent-hint
                  :hint="$t('zones.no_empty_playlists')"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="playerAdjustment"
                  item-text="name"
                  rounded
                  filled
                  item-value="value"
                  v-model="item.adjustment"
                  :label="$t('zones.adjust_multimedia_content')"
                >
                  <template v-slot:selection="data">
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.name
                        }}</v-list-item-title>
                        <small>{{ data.item.description }}</small>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <!-- marquee fields -->
            <v-row v-if="type == 'marquee'">
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="item.text"
                  :label="$t('zones.marquee_text_to_display')"
                  rounded
                  required
                  :rules="[rules.required]"
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="color"
                  v-model="item.backgroundColor"
                  required
                  :label="$t('zones.background_color')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="color"
                  v-model="item.textColor"
                  required
                  :label="$t('zones.font_color')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.number="item.fontSize"
                  :label="$t('zones.font_size')"
                  rounded
                  type="number"
                  required
                  :rules="[rules.positive]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="textSpeed"
                  item-text="name"
                  rounded
                  filled
                  required
                  :rules="[rules.required]"
                  item-value="value"
                  :loading="loading"
                  v-model="item.speed"
                  :label="$t('zones.transition_speed')"
                ></v-select>
              </v-col>
            </v-row>

            <!-- notices fields -->
            <v-row v-if="type == 'notices'">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.title"
                  :label="$t('zones.notice_title')"
                  rounded
                  required
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="item.body"
                  :label="$t('zones.notice_content')"
                  rounded
                  required
                  :rules="[rules.required]"
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="color"
                  v-model="item.backgroundColor"
                  :value="item.backgroundColor"
                  :label="$t('zones.background_color')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="color"
                  v-model="item.textColor"
                  :value="item.textColor"
                  :label="$t('zones.font_color')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.number="item.fontSizeTitle"
                  required
                  :rules="[rules.positive]"
                  :label="$t('zones.title_font_size')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.number="item.fontSizeBody"
                  required
                  :rules="[rules.positive]"
                  :label="$t('zones.content_font_size')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="textAlign"
                  item-text="name"
                  rounded
                  filled
                  required
                  :rules="[rules.required]"
                  item-value="value"
                  :loading="loading"
                  v-model="item.titleAlign"
                  :label="$t('zones.title_align')"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="textAlign"
                  item-text="name"
                  rounded
                  filled
                  required
                  :rules="[rules.required]"
                  item-value="value"
                  :loading="loading"
                  v-model="item.bodyAlign"
                  :label="$t('zones.content_align')"
                ></v-select>
              </v-col>
            </v-row>

            <!-- streaming fields -->
            <v-row v-if="type == 'streaming'">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.url"
                  :label="$t('zones.streaming_url')"
                  rounded
                  autofocus
                  placeholder="https://www.site.com/playlist.m3u8"
                  filled
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- iframe fields -->
            <v-row v-if="type == 'iframe'">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.url"
                  :label="$t('zones.iframe_url')"
                  rounded
                  autofocus
                  placeholder="https://www.site.com"
                  filled
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="type == 'time' && !loading">
              <time-component :conf="item.configuration" ref="timeRef" />
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-col cols="12" sm="12" md="12">
                <v-row justify="end">
                  <v-btn
                    :loading="loading"
                    @click="selectedItem ? update() : add()"
                    color="buttons"
                    >{{ $t("button.save") }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import timeComponent from "./components/time/index";
import { mapState } from 'vuex';

export default {
  name: "new-component",
  props: ["type", "selectedItem", "dimensions"],
  components: {
    timeComponent,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        positive: (value) => value > 0 || this.$t("rules.positive"),
        min: (v) => (v && v.length >= 3) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      playlists: [],
      textAlign: [
        { name: this.$t("zones.align.justify"), value: "justify" },
        { name: this.$t("zones.align.center"), value: "center" },
        { name: this.$t("zones.align.right"), value: "right" },
        { name: this.$t("zones.align.left"), value: "left" },
      ],
      textSpeed: [
        { name: this.$t("zones.slow"), value: "slow" },
        { name: this.$t("zones.medium"), value: "medium" },
        { name: this.$t("zones.fast"), value: "fast" },
      ],
      playerAdjustment: [
        {
          name: this.$t("zones.contain"),
          value: "contain",
          description: this.$t("zones.contain_description"),
        },
        {
          name: this.$t("zones.fill"),
          value: "fill",
          description: this.$t("zones.fill_description"),
        },
        {
          name: this.$t("zones.cover"),
          value: "cover",
          description: this.$t("zones.cover_description"),
        },
      ],
      item: {
        width: 590,
        height: 334,
        x: 100,
        y: 100,
        textColor: "#000000",
        backgroundColor: "#4ebdd9",
      },
    };
  },
  computed: {
    ...mapState(['groupId']),
    name() {
      let flag = this.type
        ? this.type
        : this.selectedItem
        ? this.selectedItem.type
        : "";

      switch (flag) {
        case "player":
          return this.$t("zones.media_player");
          break;
        case "marquee":
          return this.$t("zones.marquee");
          break;
        case "notices":
          return this.$tc("zones.notices", 1);
          break;
        case "streaming":
          return this.$tc("zones.streaming");
          break;
        case "iframe":
          return this.$tc("zones.iframe");
          break;
        default:
          return "component";
          break;
      }
    },
  },

  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        switch (this.item.type) {
          case "time":
            this.item.configuration = this.$refs.timeRef.configuration;
            break;
        }

        this.$emit("updated", this.item);
      } else {
        this.snackbarText = this.$t('messages.insert_all_fields');
        this.snackbar = true;
        return;
      }
    },
    async add() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.item.type = this.type;

        switch (this.item.type) {
          case "time":
            this.item.configuration = this.$refs.timeRef.configuration;
            break;
          case "player":
            this.item.adjustment = "fill";
            break;
          case "marquee":
            this.item.width = this.dimensions.width;
            this.item.height = 50;
            this.item.y = this.dimensions.height - 50;
            this.item.x = 0;
            this.item.textColor = this.item.textColor
              ? this.item.textColor
              : "#000000";
            this.item.backgroundColor = this.item.backgroundColor
              ? this.item.backgroundColor
              : "#4ebdd9";
            break;

          default:
            break;
        }

        this.$emit("success", this.item);
      } else {
        this.snackbarText = this.$t('messages.insert_all_fields');
        this.snackbar = true;
        return;
      }
    },
  },
  async mounted() {
    if (this.selectedItem)
      this.item = Object.assign({}, this.selectedItem.data);

    if (this.type == "player") {

      this.loading = true;
      await db
        .collection("playlists")
        .where("groupId", "==", this.groupId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let playlist = item.data();
            playlist.id = item.id;
            this.playlists.push(playlist);
          });
          this.playlists = this.playlists.filter(
            (item) => item.files.length > 0
          );
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
input[type="color"] {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
}
</style>