<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{$t('zones.select_template')}}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(item, index) of templates"
              :key="index"
              cols="12"
              class="mt-5"
              sm="3"
              md="3"
            >
              <div
                @click="formatItem(item)"
                style="width: 200px; height: 200px; cursor: pointer"
              >
                <vue-draggable-resizable
                  :w="component.width"
                  v-for="(component, i) of item"
                  :key="i"
                  :h="component.height"
                  :x="component.x"
                  :y="component.y"
                  :draggable="false"
                  :resizable="false"
                  :style="`background-color: ${component.color}`"
                  class="item-added"
                  :parent="true"
                >
                </vue-draggable-resizable>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";

export default {
  name: "default-templates",
  props: ["dimensions"],
  components: {
    VueDraggableResizable,
  },
  data() {
    return {
      templates: [
        [
          { x: 0, y: 0, width: 100, height: 100, color: "#dcdfe0" },
          { x: 100, y: 0, width: 100, height: 100, color: "#dcdfe0" },
          { x: 0, y: 100, width: 100, height: 100, color: "#dcdfe0" },
          { x: 100, y: 100, width: 100, height: 100, color: "#dcdfe0" },
        ],

        [
          { x: 4, y: 4, width: 94, height: 94, color: "#dcdfe0" },
          { x: 102, y: 4, width: 94, height: 94, color: "#dcdfe0" },
          { x: 4, y: 102, width: 94, height: 94, color: "#dcdfe0" },
          { x: 102, y: 102, width: 94, height: 94, color: "#dcdfe0" },
        ],

        [
          { x: 0, y: 0, width: 200, height: 100, color: "#dcdfe0" },
          { x: 0, y: 100, width: 200, height: 100, color: "#dcdfe0" },
        ],
        [
          { x: 0, y: 0, width: 100, height: 200, color: "#dcdfe0" },
          { x: 100, y: 0, width: 100, height: 200, color: "#dcdfe0" },
        ],

        [
          { x: 0, y: 0, width: 200, height: 100, color: "#dcdfe0" },
          { x: 0, y: 100, width: 100, height: 100, color: "#dcdfe0" },
          { x: 100, y: 100, width: 100, height: 100, color: "#dcdfe0" },
        ],

        [
          { x: 0, y: 0, width: 100, height: 100, color: "#dcdfe0" },
          { x: 100, y: 0, width: 100, height: 100, color: "#dcdfe0" },
          { x: 0, y: 100, width: 200, height: 100, color: "#dcdfe0" },
        ],

        [
          { x: 0, y: 0, width: 100, height: 200, color: "#dcdfe0" },
          { x: 100, y: 0, width: 100, height: 100, color: "#dcdfe0" },
          { x: 100, y: 100, width: 100, height: 100, color: "#dcdfe0" },
        ],

        [
          { x: 0, y: 0, width: 100, height: 200, color: "#dcdfe0" },
          { x: 100, y: 0, width: 100, height: 66.66, color: "#dcdfe0" },
          { x: 100, y: 66.66, width: 100, height: 66.66, color: "#dcdfe0" },
          { x: 100, y: 133.33, width: 100, height: 66.66, color: "#dcdfe0" },
        ],
      ],
    };
  },

  methods: {
    formatItem(item) {
      let tempItem = JSON.parse(JSON.stringify(item));
      let width = 200;
      let height = 200;

      tempItem.forEach((c) => {
        c.width = Number.parseFloat(this.dimensions.width * (c.width / width));
        c.height = Number.parseFloat(
          this.dimensions.height * (c.height / height)
        );
        c.x = Number.parseFloat(this.dimensions.width * (c.x / width));
        c.y = Number.parseFloat(this.dimensions.height * (c.y / height));

        c.widthPercentage = Number.parseFloat(
          (c.width / this.dimensions.width) * 100
        );

        c.heightPercentage = Number.parseFloat(
          (c.height / this.dimensions.height) * 100
        );

        c.xPercentage = Number.parseFloat((c.x / this.dimensions.width) * 100);
        c.yPercentage = Number.parseFloat((c.y / this.dimensions.height) * 100);
      });

      this.$emit("selected", tempItem);
    },
  },
};
</script>

<style scoped>
.item-added {
  border: 1px solid black !important;
}
</style>