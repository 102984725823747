<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        type="image"
        style="padding: 2vh; height: 100px"
      ></v-skeleton-loader>
    </div>
    <div :style="styles" v-else @click="$emit('click', 1)" class="main">
      <div>
        <span
            class="hours"
            >{{ date.getHours() || "00" }}
        </span>
      </div>
      <span>:</span>
      <span class="minutes">{{ minutes || "00" }}</span>
      <span class="am" v-if="configuration.showAM">{{ type }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "time-template1",
  props: ["configuration"],
  data() {
    return {
      date: "",
      loading: true,
    };
  },
  computed: {
    minutes() {
      return Number.parseInt(this.date.getMinutes()) < 10
        ? `0${this.date.getMinutes()}`
        : this.date.getMinutes();
    },
    type() {
      return Number.parseInt(this.date.getHours()) < 12 ? "AM" : "PM";
    },

    styles() {
      return `
            padding: 4vh;
            max-height: 100px;
            color: ${this.configuration.textColor || "white"};
            font-size: ${this.configuration.fontSize}px;
        `;
    },
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
      this.loading = false;
    }, 1000);
  },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.main span {
  margin-right: 1vw;
}

.main .am {
  font-size: 1.5vw;
}
</style>