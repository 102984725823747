<template>
    <div :style="styles">
       <h1 :style="titleStyle">{{item.title}}</h1>
       <div>
           <h1 :style="bodyStyle">{{item.body}}</h1>
       </div>
    </div>
</template>

<script>
    export default {
        name : 'notices',
        props : ['item', 'dimensions'],
        computed: {
            styles() {
                return `
                    background-color : ${this.item.backgroundColor};
                    max-height : ${this.item.height}px;
                    height : ${this.item.height}px;
                    max-width : ${this.item.width}px;
                    width : ${this.item.width}px;
                    color : ${this.item.textColor}; 
                    font-size : ${this.item.fontSize}px; 
                    overflow:hidden
                `
            },
            titleStyle() {
                return `
                    max-width : ${this.item.width}px;
                    width : ${this.item.width}px;
                    font-size : ${ this.dimensions.width * ( this.item.fontSizeTitle / 100 )}%; 
                    text-align: ${this.item.titleAlign};
                    padding: 2vw 3vw 3vw 1vw
                `
            },
            bodyStyle() {
                return `
                    max-width : ${this.item.width}px;
                    width : ${this.item.width}px;
                    font-size : ${ (this.dimensions.width - 145 ) * ( this.item.fontSizeBody / 100 )}%; 
                    text-align: ${this.item.bodyAlign};
                    padding: 0vw 3vw 3vw 3vw;
                `
            },
        }
    }
</script>