<template>
    <div :style="backStyle" class="marquee">
        <v-text-marquee :speed="speed" :content="item.text"/>
    </div>
</template>

<script>

import { VTextMarquee } from 'vue-text-marquee';

    export default {
        name : 'marquee',
        props : ['item', 'dimensions'],
        components: {
            VTextMarquee
        },
        computed: {
            backStyle() {
                return `
                    background-color : ${this.item.backgroundColor};
                    max-height : ${this.item.height}px;
                    height : ${this.item.height}px;
                    max-width : ${this.item.width}px;
                    width : ${this.item.width}px;
                    color : ${this.item.textColor}; 
                    font-size : ${ (this.dimensions.width - 145 )* ( this.item.fontSize / 100 )}%; 
                `
            },
            speed() {
                switch ( this.item.speed ) {
                    case 'slow':
                        return 50 
                    break;
                     case 'medium':
                        return 70
                    break;
                    case 'fast':
                        return 90
                    break;
                
                    default:
                        return 50
                    break;
                }
            }
        }
    }
</script>

<style scoped>
    .marquee {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .v-marquee {
        width: 100%;
    }
</style>