<template>
    <div style="width:100%">
        <v-row>
            <v-col cols="12" sm="4" md="4">
                <v-select
                    v-model="configuration.timezone"
                    :items="timeZones"
                    item-text="text"
                    disabled
                    item-value="text"
                    rounded
                    filled
                    label="Zona horaria"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                    v-model.number="configuration.fontSize"
                    required
                    label="Tamaño de la letra"
                    rounded
                    type="number"
                    filled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                    type="color"
                    v-model="configuration.backgroundColor"
                    required
                    label="Color del fondo"
                    rounded
                    filled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                    type="color"
                    v-model="configuration.textColor"
                    required
                    label="Color de la letra"
                    rounded
                    filled
                ></v-text-field>
            </v-col>
                <v-col cols="12" sm="2" md="2">
                <v-checkbox
                    v-model="configuration.showAM"
                    label="Mostrar AM/PM"
                    class="mt-2"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="3" md="3">
                <template1 :width="250" :height="50" :configuration="configuration"/>
            </v-col>
            <v-col v-for="item of 3" :key="item" cols="12" sm="3" md="3">
                <div class="development">
                    <p>En desarollo</p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import template1 from './template1'
    import template2 from './template2'
    import timeZonesArray from '@/assets/timeZones.json'

    export default {
        name: 'time-component',
        props: ['conf'],
        components : {
            template1,
            template2
        },
        data() {
            return {
                configuration : {
                    backgroundColor : 'black',
                    textColor : 'red',
                    timezone: 'America/Tegucigalpa',
                    fontSize : 50,
                    showAM: true
                }
            }
        },
        computed: {
            timeZones() {
                return timeZonesArray
            },
        },
        mounted(){
            if ( this.conf )
                this.configuration = Object.assign({}, this.conf)
        }
    }
</script>

<style scoped>
    .development {
        width: 100%;
        height: 100%;
        background-color: rgba(128, 128, 128, 0.527);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .development p {
        color: gray;
    }
</style>