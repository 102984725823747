<template>
  <div :style="`max-width : ${width}; max-height:${height}`">
    <video
      id="video"
      class="video-js"
      preload="none"
      crossorigin="true"
      controls
      :width="width"
      :height="height"
      muted
    ></video>
  </div>
</template>


<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";

export default {
  name: "streaming",
  props: ["width", "height", "src"],

  data() {
    return {
      player: null,
    };
  },

  watch: {
    width(value) {
      let player = document.querySelector(".video-dimensions");
      player.style.width = `${value}px`;
    },
    height(value) {
      let player = document.querySelector(".video-dimensions");
      player.style.height = `${value}px`;
    },
    src(){
      this.player.dispose();
      this.showPlayer()
    }
  },

  methods: {
    showPlayer() {
      let extension = this.src ? this.src.split(".").pop() : null;

      if (extension && (extension == "m3u8" || extension == "m3u")) {
        document.getElementById(
          "video"
        ).innerHTML = `<source width='${this.width}' height='${this.height}' src='${this.src}' type='application/x-mpegURL'>`;
        this.player = videojs("video");
        this.player.play();
      }
    },
  },
  mounted() {
    this.showPlayer();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
